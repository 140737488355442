import React,{useState,useEffect,useLayoutEffect,useRef} from 'react'
import {Row,Col,Image,Modal,Form,Button,InputGroup,FormControl,Collapse,Accordion} from 'react-bootstrap'
import {Link,useHistory,useParams} from 'react-router-dom'
import Card from '../../../components/Card'
import HTMLFlipBook from 'react-pageflip';
import '../../../index.css'
import axios from 'axios'
import Swal from 'sweetalert2'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

// img
import avatars1 from '../../../assets/images/avatars/01.png'
import auth1 from '../../../assets/images/auth/04.png'
import background from '../../../assets/images/dashboard/background.jpg'

const ViewEbook = () => {
    const { view_code } = useParams();
    const { user_code } = useParams();
    const [open, setOpen] = useState(false);

    const [pdfimg, setPdfImg] = useState([]);
    const [apilink, setAPIlink] = useState([]);
    const [apisoal, setAPIsoal] = useState([]);
    const [apivideo, setAPIvid] = useState([]);
    const [apidoc, setAPIdoc] = useState([]);
    const [namabuku, setNamaBuku] = useState('');
    const [widthx, setWidth] = useState('');
    const [heightx, setHeight] = useState('');
    const token = localStorage.getItem("token");
    const [pakecover, setCover] = useState(true);
    const [portrait, setPortrait] = useState(true);
    const [link, setLink] = useState('');

    const [viewlink, showLink] = useState(false);
    const closeLink = () => showLink(false);
    const modalLink = () => {
        showLink(true);
    }
    const [orientation, setOrientation] = useState('');

    useEffect(() => {
        getResolution();
        getData();
        getLink();
        getSoal();
        getVideo();
        getDoc();

        // window.addEventListener("orientationchange", function() {
        //     window.location.reload();
        // }, false)
    }, []);
    
    function getResolution() {
        let width = window.innerWidth;
        let height = window.innerHeight;
        let widthBook = 0;
        let heightBook = 0;
        if (width > height){
            widthBook = width*0.4;
            heightBook = ((width*0.4)*3)/2;

            if (heightBook > height) {
                heightBook = height;
            }
            
            setCover(true);
            setPortrait(false);
            console.log("w : "+ width +" > h : "+ height);
        } else {
            widthBook = width*(width/height);
            heightBook = height*(width/height);

            if (height < 1000) {
                widthBook = width;
                heightBook = (width*3)/2;
            }
            
            setCover(false);
            setPortrait(true);
            console.log("w : "+ width +" < h : "+ height);
        }
        setWidth(widthBook);
        setHeight(heightBook);
        console.log(widthBook);
        console.log(heightBook);
    }

    const getData = async (e) => {
        const conv = await axios.get('https://api.alazhar2.com/node/admin/books/detail?books='+view_code.replaceAll("-","/"));
        if (conv.data.background != null){
            background = "https://api.alazhar2.com/upload/books/"+conv.data.file+"/background/"+conv.data.background;
        }
        setNamaBuku(conv.data.file);
        setPdfImg(conv.data.data);
    }

    const getLink = async (e) => {
        const res = await axios.get('https://api.alazhar2.com/node/admin/books/attribute?book='+view_code.replaceAll("-","/")+'&type=Link');
        setAPIlink(res.data.data);
    }

    const getSoal = async (e) => {
        const res = await axios.get('https://api.alazhar2.com/node/admin/books/attribute?book='+view_code.replaceAll("-","/")+'&type=Question');
        setAPIsoal(res.data.data);
    }

    const getVideo = async (e) => {
        const res = await axios.get('https://api.alazhar2.com/node/admin/books/attribute?book='+view_code.replaceAll("-","/")+'&type=Video');
        setAPIvid(res.data.data);
    }

    const getDoc = async (e) => {
        const res = await axios.get('https://api.alazhar2.com/node/admin/books/attribute?book='+view_code.replaceAll("-","/")+'&type=Doc');
        setAPIdoc(res.data.data);
    }

    const findpage = useRef();
    const [nopage, setNoPage] = useState(0);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    //zoom in zoom out setting
    const [canPanning, setCanPanning] = useState(false);
    const [scaleZoom, setScaleZoom] = useState(1);

    const getScaleTransform = (ref, event) => {
        if (event.scale == 1) {
            setCanPanning(false);
        } else {
            setCanPanning(true);
        }
        //setScaleZoom(event.scale);
      };

    const carihalaman = () => {
        if (nopage > (pdfimg.length-1)) {
            Swal.fire({
                title: "Melebihi Total Halaman",
                text: "Mohon masukkan nomor halaman yang sesuai",
                icon: 'warning',
            })
            return;
        } else {
            findpage.current.pageFlip().flip(parseInt(nopage),'bottom');
            // handleClose();
        }
    }

    return (
        <>
        <Modal show={show} onHide={handleClose} size="sm" aria-labelledby="contained-modal-title-vcenter" centered>
            <Modal.Header closeButton>
                <Modal.Title>CARI HALAMAN</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group>
                    <Form.Label>Total Halaman : ({(pdfimg.length-1)})</Form.Label>
                    <Form.Control 
                        type="number" 
                        placeholder="No Halaman Yang Dicari" 
                        onChange={(e) => setNoPage(e.target.value)}
                        id="nohal"
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Batal
                </Button>
                <Button variant="primary" onClick={() => carihalaman()}>
                    Cari
                </Button>
            </Modal.Footer>
        </Modal>

        <section style={{overflow: "hidden", height: "100%"}}>
            <Row style={{overflow: "hidden", height: "100%"}}>            
                <Col md="12" className="p-0" style={{overflow: "hidden", height: "100%"}}>  
                    <div style={{ 
                        backgroundImage: `url(${background})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        display: "flex", 
                        justifyContent: "center",
                        overflow: "hidden", 
                        width: "100%",
                        height: "100%"
                    }}>

                        {/* <div className='section_find'>
                            <h2>
                                Cari Halaman : 
                                <Form.Control 
                                    type="number" 
                                    placeholder="No Halaman Yang Dicari" 
                                    onChange={(e) => setNoPage(e.target.value)}
                                    id="nohal"
                                />
                                / ({(pdfimg.length-1)})
                            </h2>
                        </div> */}

                        {/* <div className='btn btn-dark section__find'>
                            <span>
                                Cari Halaman : 
                                <input type='number' placeholder='No Halaman' id="nohal" onChange={(e) => setNoPage(e.target.value)} className='mx-2' style={{width: "7%", fontSize: "18px", borderRadius: "8px"}} /> 
                                / {(pdfimg.length-1)} 
                                <Button variant="primary" size="sm" style={{fontSize: "18px", padding: "2px 10px"}} className='ms-4' onClick={() => carihalaman()}>
                                    CARI
                                </Button>
                            </span>
                        </div> */}

                        <nav class="navbar fixed-bottom navbar-dark d-flex justify-content-center" style={{background: "transparent"}}>
                            {/* <span style={{fontSize: "22px"}}>
                                Cari Halaman : 
                                <input type='number' placeholder='No Halaman' id="nohal" onChange={(e) => setNoPage(e.target.value)} className='mx-2' style={{width: "120px", fontSize: "18px", borderRadius: "8px"}} /> 
                                / {(pdfimg.length-1)} 
                                <Button variant="primary" size="sm" style={{fontSize: "18px", padding: "0px 10px"}} className='ms-4' onClick={() => carihalaman()}>
                                    CARI
                                </Button>
                            </span> */}
                            <Accordion>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header></Accordion.Header>
                                    <Accordion.Body>
                                        <span style={{fontSize: "22px"}}>
                                            Cari Halaman : 
                                            <input type='number' placeholder='No Halaman' id="nohal" onChange={(e) => setNoPage(e.target.value)} className='mx-2' style={{width: "120px", fontSize: "18px", borderRadius: "8px"}} /> 
                                            / {(pdfimg.length-1)} 
                                            <Button variant="primary" size="sm" style={{fontSize: "18px", padding: "0px 10px"}} className='ms-4' onClick={() => carihalaman()}>
                                                CARI
                                            </Button>
                                        </span>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </nav>

                        <TransformWrapper
                            doubleClick={{disabled: true}}
                             panning={{disabled: !canPanning}}
                             onTransformed={getScaleTransform}>
                            <TransformComponent>
                        <HTMLFlipBook width={widthx} height={heightx} showCover={pakecover} drawShadow={false} usePortrait={portrait} ref={findpage}
                            style={{
                                overflow: "hidden"
                            }}

                            mobileScrollSupport={true}
                            disableFlipByClick={true}>
                            {pdfimg?.map((item,index) => (
                                <div className="demoPage" key={item.code} id={item.pages}
                                    style={{
                                        position: "absolute"
                                    }}
                                >
                                    <img
                                        src={"https://api.alazhar2.com/upload/books/"+namabuku+"/detail/"+item.file}
                                        style={{
                                            position: "absolute",
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    />
                                    {apivideo.map((itemvid,indexvid) => (
                                        itemvid.pages != item.pages ? <></> :
                                            <div key={indexvid} style={{position: "absolute", top: `${pakecover ? 35 : 25}px`}}>
                                                <iframe src={itemvid.link} 
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen
                                                    style={{
                                                        zIndex: 99999,
                                                        position: "absolute",
                                                        width: `${String(parseInt(itemvid.width.replace("px",""))/itemvid.widthbook*widthx)}px`,
                                                        height: `${String(parseInt(itemvid.height.replace("px",""))/(itemvid.widthbook*3/2)*heightx)}px`,
                                                        top: `${(itemvid.y*heightx)/100}px`,
                                                        left: `${(itemvid.x*widthx)/100}px`
                                                    }}
                                                >                                                    
                                                </iframe>
                                            </div>
                                    ))}
                                    {apilink.map((itemlink,indexlink) => (
                                        itemlink.pages != item.pages ? <></> :
                                            <div key={indexlink} style={{position: "absolute", top: `${pakecover ? 35 : 25}px`}}>
                                                <Button onClick={() => {modalLink();setLink(itemlink.link);}}
                                                    style={{
                                                        zIndex: 99999,
                                                        position: "absolute",
                                                        width: `${String(parseInt(itemlink.width.replace("px",""))/itemlink.widthbook*widthx)}px`,
                                                        height: `${String(parseInt(itemlink.height.replace("px",""))/(itemlink.widthbook*3/2)*heightx)}px`,
                                                        top: `${(itemlink.y*heightx)/100}px`,
                                                        left: `${(itemlink.x*widthx)/100}px`,
                                                        background: "#1E90FF",
                                                        color: "black",
                                                        fontWeight: "bold",
                                                        border: "2px solid black",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    LINK
                                                </Button>
                                            </div>
                                    ))}
                                    {apisoal.map((itemsoal,indexsoal) => (
                                        itemsoal.pages != item.pages ? <></> :
                                            <div key={indexsoal} style={{position: "absolute", top: `${pakecover ? 35 : 25}px`}}>
                                                <iframe src={`/question_page/${view_code}/${itemsoal.link.replaceAll("/","-")}/${user_code}`} 
                                                    style={{
                                                        zIndex: 99999,
                                                        position: "absolute",
                                                        width: `${String(parseInt(itemsoal.width.replace("px",""))/itemsoal.widthbook*widthx)}px`,
                                                        height: `${String(parseInt(itemsoal.height.replace("px",""))/(itemsoal.widthbook*3/2)*heightx)}px`,
                                                        top: `${(itemsoal.y*heightx)/100}px`,
                                                        left: `${(itemsoal.x*widthx)/100}px`,
                                                        borderRadius: "20px"
                                                    }}
                                                >

                                                </iframe>
                                            </div>
                                    ))}
                                    {apidoc.map((itemdoc,indexdoc) => (
                                        itemdoc.pages != item.pages ? <></> :
                                            <div key={indexdoc} style={{position: "absolute", top: `${pakecover ? 35 : 25}px`}}>
                                                <iframe src={itemdoc.link}  
                                                    frameborder="0" allowfullscreen="false" mozallowfullscreen="false" webkitallowfullscreen="false"
                                                    style={{
                                                        zIndex: 99999,
                                                        position: "absolute",
                                                        width: `${String(parseInt(itemdoc.width.replace("px",""))/itemdoc.widthbook*widthx)}px`,
                                                        height: `${String(parseInt(itemdoc.height.replace("px",""))/(itemdoc.widthbook*3/2)*heightx)}px`,
                                                        top: `${(itemdoc.y*heightx)/100}px`,
                                                        left: `${(itemdoc.x*widthx)/100}px`,
                                                        borderRadius: "20px"
                                                    }}>
                                                </iframe>                                                 
                                            </div>
                                    ))}
                                </div>
                            ))}
                        </HTMLFlipBook>
                        </TransformComponent>
                        </TransformWrapper>
                    </div>
                </Col>
            </Row>
        </section>

        <Modal fullscreen={true} show={viewlink} onHide={closeLink}>
            <Modal.Header closeButton>
               <Modal.Title>View Link</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{overflow: "hidden"}}>
                <iframe src={link} style={{width: "100%", height: "100%"}}></iframe>
            </Modal.Body>
        </Modal>
        </>
    )
}

export default ViewEbook
